$Font1: "Roboto", "Arial", "Sans-Serif";
$Font2: "Oswald", "Arial", "Sans-Serif";

html,
body {
  font-size: 62.5%; // 1rem === 10px (1rem === 16px)
}

$BasicColor1: #ffffff; /* white */
$BasicColor2: #000000; /* black */
$BasicColor5: #d83838; /* error red */
$BasicColor6: #feefee; /* light error red */

$MainColor1: #067889; /* green use for link and button */
$MainColor2: #415665; /* dark Grey use for text title */
$MainColor3: #757575; /* grey use for text */
$MainColor4: #f9f9fb; /* grey use for background field */
$MainColor5: #e1e5e8; /* grey use for border field */
$MainColor6: #37434f; /* grey use for background nav */
$MainColor7: #a7b0b9; /* grey use for text navigation */
$MainColor8: #e9f7f9; /* light green use for link and button */

/* Password strength meter colours */
$Colour_VeryWeak: #e1e5e8; /* Very Weak: Grey */
$Colour_Weak: #d83838; /* Weak: Red */
$Colour_SoSo: #ffbf00; /* So-So: Amber */
$Colour_Good: #afc882; /* Good: Green */
$Colour_Great: #7cd838; /* Great: Green */

* {
  box-sizing: border-box;
  font-family: $Font1;
}
@import "./components/index.scss";
