.Deposit {
  font-size: 1.4rem;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  // background-color: $MainColor4;
  .FormRow {
    display: flex;
    width: 100%;
    // padding: 0 4rem;
    justify-content: space-between;
    align-items: flex-start;
    h3 {
      width: 48%;
      margin: 2rem 0;
    }
    .FormBox {
      width: 48%;
    }
  }
}

.FormContainer .FormRow.CreditCardSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
  .FormBox {
    width: 100%;
  }
}

/* Bonuses Section */
.BonusContainer {
  display: flex;
  // background-color: $MainColor4;
  // padding: 3rem 4rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0;
  .Bonus {
    min-width: 220px;
    border: 0.1rem solid #d8d7d3;
    background-color: white;
    width: 30%;
    padding: 1.5rem;
    margin: 1rem;
    .container {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.Bonus:hover,
.Bonus:focus {
  transform: scale(1.05);
  box-shadow: 10px;
}

.DepositLimits {
  // margin: 0 4rem;
  margin-bottom: 1rem;
  padding: 1.5rem 0;
  background-color: $Colour_SoSo;
  color: white;
  font-family: $Font2;
  p {
    font-family: $Font2;
    font-size: 2rem;
    padding: 0 3rem;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    justify-content: space-between;
    span {
      font-family: $Font2;
    }
  }
}

.SubmitContainer {
  margin-top: 2rem;
  .SubmitBtn {
    // margin: 0 auto;
    background-color: $Colour_Weak;
    color: white;
    font-family: $Font2;
    border: none;
    font-size: 2rem;
    padding: 1.5rem 3rem;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
  }
}

/* E-Wallet/Other Deposit */
.EWalletDeposit {
  display: flex;
  flex-direction: column;
  .FormBoxContainer {
    display: flex;
    justify-content: center;
  }
  .FormBox {
    flex: 1 1;
  }
  .FormBox:nth-of-type(1) {
    padding-right: 2rem;
  }
  .FormBox:nth-of-type(2) {
    padding-left: 2rem;
  }
}

.LogoContainer {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.CryptoSelect {
  display: flex;
  flex-wrap: wrap;
  .CryptoItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 3rem;
    h4 {
      text-transform: uppercase;
      text-align: center;
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  button {
    background-color: rgba(255, 255, 255, 0);
    border: none;
  }
  button:active,
  button:focus {
    outline: none;
  }
}
.BackBtn {
  background-color: $Colour_Weak;
  color: white;
  font-family: $Font2;
  border: none;
  font-size: 2rem;
  padding: 0 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.EWalletHeader {
  display: flex;
  justify-content: space-between;
}

.MoneyGramControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .StepBtn {
    padding: 0.7rem 1rem;
  }
  p {
    color: #3b3b3b;
  }
}

.MoneyGramInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #3b3b3b;
    margin-top: 0;
    span {
      font-weight: bold;
    }
  }
  .FormBox {
    width: 100%;
  }
}

.FinalNote {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 0.5rem;
  color: #3b3b3b;
}

.InputContainer {
  // padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
  .FormBoxContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 3rem 0;
  }
  .FormBox {
    width: 45%;
  }
}

.ErrorLabel {
  color: $BasicColor5;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.react-datepicker-wrapper {
  width: 100%;
}
.SuccessModal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  .SuccessContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    height: fit-content;
    font-size: 1.4rem;
    h2 {
      font-size: 2rem;
    }
    .SuccessContent {
      margin: 0 auto;
    }
    .SuccessHTML {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
.CloseModalBtn {
  display: flex;
  background-color: $MainColor1;
  color: white;
  font-family: "Oswald", "Arial", "Sans-Serif";
  border: none;
  padding: 0.6rem 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  width: fit-content;
}

/* Mobile Styling */

.FormSectionMob {
  padding-bottom: 0;
  .HalfWidthFields {
    width: 100%;
  }
}

.DepositBonusesMob {
  .BonusContainer {
    .Bonus {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .BonusContainer {
    .Bonus {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .EWalletDeposit {
    .FormBoxContainer {
      display: block;
      .FormBox {
        padding: 0;
        width: 100%;
      }
    }
  }
  .InputContainer {
    .FormBoxContainer {
      display: block;
      .FormBox {
        // padding: 0;
        margin: 1rem 0;
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 1170px) {
  .BonusContainer {
    .Bonus {
      min-width: 200px;
    }
  }
}
@media only screen and (max-width: 905px) {
  .BonusContainer {
    .Bonus {
      min-width: 180px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .Page {
    width: 70%;
  }
}

@media only screen and (max-width: 470px) {
  .CryptoSelect {
    .CryptoItem {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 432px) {
  .LogoContainer {
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 400px) {
  .Page {
    width: 80%;
  }
  .DepositLimits {
    padding: 1rem 0rem;
    p {
      font-size: 1.8rem;
      padding: 0 2rem;
    }
  }
}
@media only screen and (max-width: 300px) {
  .MoneyGramControls {
    .Controls {
      display: flex;
      flex-direction: column;
      width: 100%;
      button {
        margin-bottom: 1rem;
      }
    }
  }
}
