.Payout {
  .Main {
    .Content {
      border: 1px solid $MainColor5;

      .SectionContainer {
        display: flex;
        justify-content: space-between;
        .Section {
          width: 48%;
          // padding: 3rem 4rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            display: flex;
            justify-content: space-between;
            font-family: $Font2;
            font-size: 1.6rem;
            text-transform: uppercase;
            margin-top: 0;
            border-bottom: 0.1rem solid $MainColor5;
            span {
              color: $Colour-SoSo;
              font-family: $Font2;
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

.PayoutLimits {
  // margin: 0 4rem;
  padding: 1.5rem 0;
  background-color: $Colour_SoSo;
  color: white;
  font-family: $Font2;
  display: flex;
  p {
    font-family: $Font2;
    font-size: 2rem;
    padding: 0 3rem;
    text-transform: uppercase;
    margin: 0 auto;
    span {
      font-family: $Font2;
    }
  }
}

.PayoutInitialForm {
  display: flex;
  // padding: 3rem 4rem;
  .FormBox {
    flex: 1 1;
  }
  .FormBox:nth-of-type(1) {
    padding-right: 2rem;
  }
  .FormBox:nth-of-type(2) {
    padding-left: 2rem;
  }
}

.PayoutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.PayoutMoneyGramContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
  .FormBox {
    width: 40%;
    margin: 1rem 2rem;
  }
}

.FormField {
  textarea {
    width: 100%;
    background-color: #efeeea;
    border: 0.1rem solid #d8d7d3;
    padding: 1.4rem 1.5rem;
    line-height: 2rem;
    color: #929292;
    font-size: 1.4rem;
  }
}

.CreditCardPayout {
  font-size: 1.4rem;
  p {
    margin: 0;
    padding: 1rem;
  }
}

.SuccessContainer {
  padding: 0 4rem;
  margin-top: 1rem;
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 910px) {
  .PayoutMoneyGramContainer {
    .FormBox {
      width: 100%;
      margin: 1rem 0;
    }
  }
}
@media only screen and (max-width: 900px) {
  .Payout {
    .Main {
      .Content {
        padding: 3rem 3rem;
        .SectionContainer {
          flex-direction: column;
          width: 100%;
          .Section {
            width: 100%;
          }
        }
      }
    }
    .InputContainer {
      .FormBoxContainer {
        flex-direction: column;
      }
      .FormBox {
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .PayoutLimits {
    padding: 1rem 0rem;
    p {
      font-size: 1.8rem;
      padding: 0 2rem;
    }
  }
}
