h2 {
  font-family: $Font2;
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
  color: #11577e;
  margin: 0px auto 2rem auto;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;
  width: 98%;
  text-transform: uppercase;
}
h3 {
  font-family: $Font2;
  color: #11577e;
  font-size: 2.4rem;
  margin-top: 0;
  text-transform: capitalize;
}

.ComingSoonContainer {
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ComingSoon {
  font-size: 4rem;
  font-family: $Font2;
}

.Page {
  display: flex;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  flex-direction: column;
}

.Main {
  width: 100%;
}

.Content {
  border: 0.1rem solid $MainColor5;
  border-top: 0;
  background-color: white;
  padding: 3rem 4rem;
}

/* Nav */
.Nav {
  display: flex;
  .Links {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .NavItem {
    border: none;
    font-family: $Font2;
    padding: 1.3rem 1.5rem;
    font-size: 1.6rem;
    margin-right: 0.4rem;
    cursor: pointer;
    text-transform: uppercase;
    color: white;
    background-color: #11577e;
    border-top: 0.1rem solid white;
    border-left: 0.1rem solid white;
    border-right: 0.1rem solid white;
    .NavItem {
      margin-right: 0;
    }
  }
  .NavItem:focus {
    outline: none;
  }

  .Active {
    background-color: white;
    color: $MainColor3;
    border-top: 0.1rem solid $MainColor5;
    border-left: 0.1rem solid $MainColor5;
    border-right: 0.1rem solid $MainColor5;
  }

  .BorderBottom {
    border-bottom: 0.1rem solid $MainColor5;
    flex: 1;
  }
  .NoMarginRight {
    margin-right: 0;
  }
  .Links {
    .NavItem:last-of-type {
      margin-right: 0;
    }
  }
}

/* Forms */
.FormBox {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  .FormLabel {
    line-height: 1.8rem;
    margin-bottom: 1rem;
    color: #3b3b3b;
  }
  .FormField {
    width: 100%;
    .EditBox {
      line-height: 2rem;
      width: 100%;
      padding: 1.4rem 1.5rem;
      border: 0.1rem solid #d8d7d3;
      background-color: #efeeea;
      box-sizing: border-box;
      font-family: $Font1;
      color: #929292;
      -webkit-appearance: none;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: default;
    }
    .ViewOnly {
      background-color: white;
    }
    .CustomSelect {
      .CustomSelect__control {
        border-radius: 0;
        padding: 0;
        margin: 0;
        line-height: 2rem;
        width: 100%;
        border: 0.1rem solid #d8d7d3;
        background-color: #efeeea;
        box-sizing: border-box;
        font-family: $Font1;
        color: #929292;
        -webkit-appearance: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
      }
      .CustomSelect__value-container {
        padding: 1rem 1.1rem;
      }
    }
  }
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 3.5rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    margin-top: 4rem;
    margin-bottom: 2.2rem;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #efeeea;
    border: 0.1rem solid #d8d7d3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    height: 1.4rem;
    width: 1.4rem;
    background-color: #11577e;
    border-radius: 0.3rem;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
  }
}
.HalfWidthFields {
  display: flex;
  justify-content: space-between;
  width: 48%;
  .FormBox {
    width: 48%;
  }
}
.Divider {
  margin: auto 0;
  flex: 1;
  border-left: 0.1rem solid $MainColor5;
  height: 66rem;
}

.Btn {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-family: $Font2;
  color: white;
}
.Btn:hover,
.Btn:focus {
  outline: none;
}

.BtnGreen {
  background-color: #2c8a2c;
}
.BtnRed {
  background-color: $Colour_Weak;
}
.ErrorMessage {
  padding: 0rem 4rem;
  padding-bottom: 1rem;
  font-size: 1.4rem;
  color: $BasicColor5;
}

.ErrorPage {
  display: flex;
  justify-content: center;
  .ErrorContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    text-align: center;
    width: 20%;
    height: 40%;
    color: $MainColor3;
    margin-top: 10rem;
    img {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 850px) {
  .Main {
    .Nav {
      .Links {
        width: 100%;
        .NavItem {
          padding: 0.8rem 0;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .Content {
    padding: 3rem 2rem;
  }
}
@media only screen and (max-width: 300px) {
  .Content {
    padding: 3rem 1.5rem;
  }
}

@import "./pages/deposit/deposit.scss";
@import "./pages/payout/payout.scss";
